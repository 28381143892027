<template>
  <div class="log-viewer w-full h-full max-w-full mx-auto p-6 bg-white rounded-lg shadow-lg flex flex-col">
    <h1 class="text-2xl font-bold mb-4">LightRP Logs</h1>
    <div class="controls mb-4 flex justify-between">
      <button @click="startLogs" class="btn btn-primary">Start Logs</button>
      <button @click="stopLogs" class="btn btn-secondary" :disabled="!isLogging">Stop Logs</button>
      <button @click="clearLogs" class="btn btn-danger">Clear Logs</button>
    </div>
    <div class="log-container bg-black text-green-400 p-4 rounded-lg overflow-y-auto flex-grow">
      <div class="log" v-for="(log, index) in logs" :key="index">
        <span class="timestamp text-gray-500">{{ log.timestamp }}</span
        >: {{ log.message }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, inject } from 'vue'

export default {
  props: {
    username: String,
  },
  setup() {
    const logs = ref([])
    const isLogging = ref(false)
    const { getSocket } = inject('socket')
    let socket = null

    const startLogs = () => {
      if (socket) {
        return
      }
      socket = getSocket()
      if (socket) {
        isLogging.value = true
        socket.emit('start-logs')
        socket.on('log', (data) => {
          logs.value.push({
            message: data,
            timestamp: new Date().toLocaleTimeString(),
          })
          scrollToBottom()
        })
      }
    }

    const stopLogs = () => {
      if (socket) {
        socket.off('log')
        socket = null
        isLogging.value = false
      }
    }

    const clearLogs = () => {
      logs.value = []
    }

    const scrollToBottom = () => {
      const logContainer = document.querySelector('.log-container')
      if (logContainer) {
        logContainer.scrollTop = logContainer.scrollHeight
      }
    }

    onMounted(startLogs)
    onUnmounted(stopLogs)

    return {
      logs,
      isLogging,
      startLogs,
      stopLogs,
      clearLogs,
    }
  },
}
</script>

<style scoped>
.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-secondary {
  background-color: #6c757d;
  color: #fff;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

.btn-danger {
  background-color: #dc3545;
  color: #fff;
}

.btn-danger:hover {
  background-color: #c82333;
}

.log-container {
  font-family: monospace;
  white-space: pre-wrap;
  max-height: calc(100vh - 300px); /* Adjust the max height to fit within the viewport */
  overflow-y: auto; /* Ensure scrolling if content overflows */
}

.timestamp {
  color: #888;
}
</style>
