import { io } from 'socket.io-client'
import { reactive } from 'vue'

const state = reactive({
  socket: null,
  token: null,
})

function setSocketToken(token) {
  state.socket = io('https://lightrp-logs-back.arochka.com', {
    auth: {
      token: token,
    },
  })

  if (!state.socket.connected) {
    return false
  }

  state.token = token
  return true
}

function getSocket() {
  return state.socket
}

export function useSocket() {
  return {
    setSocketToken,
    getSocket,
  }
}

export function provideSocket(app) {
  app.provide('socket', {
    state,
    setSocketToken,
    getSocket,
  })
}
