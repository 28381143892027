<template>
  <div class="h-screen flex items-center justify-center bg-gray-100">
    <div v-if="!loggedIn" class="w-full max-w-md bg-white p-8 rounded-lg shadow-lg">
      <h2 class="text-2xl font-bold mb-6 text-center">LightRP Logs - Login</h2>
      <form @submit.prevent="login" class="space-y-4">
        <div>
          <label for="username" class="block text-gray-700">Username</label>
          <input type="text" id="username" v-model="username" class="w-full mt-1 p-2 border border-gray-300 rounded-lg" required />
        </div>
        <div>
          <label for="password" class="block text-gray-700">Password</label>
          <input type="password" id="password" v-model="password" class="w-full mt-1 p-2 border border-gray-300 rounded-lg" required />
        </div>
        <button type="submit" class="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition">Login</button>
      </form>
    </div>
    <div v-else class="w-full bg-white p-8 rounded-lg shadow-lg">
      <LogViewer />
      <button @click="logout" class="mt-4 w-full bg-red-500 text-white py-2 rounded-lg hover:bg-red-600 transition">Logout</button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, inject } from 'vue'
import LogViewer from './components/LogViewer.vue'

export default {
  components: {
    LogViewer,
  },
  setup() {
    const loggedIn = ref(false)
    const username = ref('')
    const password = ref('')
    const { setSocketToken, getSocket } = inject('socket')

    const checkLocalStorage = () => {
      const token = localStorage.getItem('token')
      if (token) {
        if (setSocketToken(token)) {
          loggedIn.value = true
        }
      }
    }

    const login = async () => {
      try {
        const response = await fetch('https://lightrp-logs-back.arochka.com/authenticate', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username: username.value, password: password.value }),
        })

        if (!response.ok) {
          throw new Error('Invalid credentials')
        }

        const data = await response.json()
        localStorage.setItem('token', data.token)
        setSocketToken(data.token)
        loggedIn.value = true
      } catch (error) {
        alert(error.message)
      }
    }

    const logout = () => {
      localStorage.removeItem('token')
      loggedIn.value = false
      const socket = getSocket()
      if (socket) {
        socket.disconnect()
      }
    }

    onMounted(checkLocalStorage)

    return {
      loggedIn,
      username,
      password,
      login,
      logout,
    }
  },
}
</script>

<style>
/* Ajoutez des styles supplémentaires selon vos besoins */
</style>
